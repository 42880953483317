import * as _is2 from "type/value/is";
var _is = _is2;
try {
  if ("default" in _is2) _is = _is2.default;
} catch (e) {}
import * as _is4 from "type/plain-function/is";
var _is3 = _is4;
try {
  if ("default" in _is4) _is3 = _is4.default;
} catch (e) {}
import * as _assign2 from "es5-ext/object/assign";
var _assign = _assign2;
try {
  if ("default" in _assign2) _assign = _assign2.default;
} catch (e) {}
import * as _normalizeOptions2 from "es5-ext/object/normalize-options";
var _normalizeOptions = _normalizeOptions2;
try {
  if ("default" in _normalizeOptions2) _normalizeOptions = _normalizeOptions2.default;
} catch (e) {}
import * as _contains2 from "es5-ext/string/#/contains";
var _contains = _contains2;
try {
  if ("default" in _contains2) _contains = _contains2.default;
} catch (e) {}
var exports = {};
var isValue = _is,
  isPlainFunction = _is3,
  assign = _assign,
  normalizeOpts = _normalizeOptions,
  contains = _contains;
var d = exports = function (dscr, value /*, options*/) {
  var c, e, w, options, desc;
  if (arguments.length < 2 || typeof dscr !== "string") {
    options = value;
    value = dscr;
    dscr = null;
  } else {
    options = arguments[2];
  }
  if (isValue(dscr)) {
    c = contains.call(dscr, "c");
    e = contains.call(dscr, "e");
    w = contains.call(dscr, "w");
  } else {
    c = w = true;
    e = false;
  }
  desc = {
    value: value,
    configurable: c,
    enumerable: e,
    writable: w
  };
  return !options ? desc : assign(normalizeOpts(options), desc);
};
d.gs = function (dscr, get, set /*, options*/) {
  var c, e, options, desc;
  if (typeof dscr !== "string") {
    options = set;
    set = get;
    get = dscr;
    dscr = null;
  } else {
    options = arguments[3];
  }
  if (!isValue(get)) {
    get = undefined;
  } else if (!isPlainFunction(get)) {
    options = get;
    get = set = undefined;
  } else if (!isValue(set)) {
    set = undefined;
  } else if (!isPlainFunction(set)) {
    options = set;
    set = undefined;
  }
  if (isValue(dscr)) {
    c = contains.call(dscr, "c");
    e = contains.call(dscr, "e");
  } else {
    c = true;
    e = false;
  }
  desc = {
    get: get,
    set: set,
    configurable: c,
    enumerable: e
  };
  return !options ? desc : assign(normalizeOpts(options), desc);
};
export default exports;